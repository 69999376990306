import { FC } from 'react';
// components
import PageLayout from 'src/components/PageLayout';
import Intro from 'src/components/Intro';
// hooks
import useMVPIntroData from 'src/cms/data/mvp/useMVPIntroData';
// sections
import DiscoveryPhase from './sections/DiscoveryPhase';
import HowItWorks from './sections/HowItWorks';
import Tools from './sections/Tools';
import { PageProps } from 'gatsby';

const MVP: FC<PageProps> = () => {
  // hooks
  const { title, mobileTitle, description } = useMVPIntroData();

  return (
    <PageLayout>
      <Intro
        title={title}
        mobileTitle={mobileTitle}
        description={description}
        icon='mvpDrawing'
      />
      <HowItWorks />
      <DiscoveryPhase />
      <Tools />
    </PageLayout>
  );
};

export default MVP;
