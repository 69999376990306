// components
import Seo from 'src/components/Seo';
// hooks
import useMvpMetaData from 'src/cms/data/mvp/useMvpMetaData';
// types
import type { HeadFC } from 'gatsby';

export const Head: HeadFC = ({ location }) => {
  const mvpMetaData = useMvpMetaData();

  return (
    <Seo
      href={`https://seedium.io${location.pathname}`}
      title={mvpMetaData?.title}
      description={mvpMetaData?.description}
      schema={mvpMetaData?.schema}
    />
  );
};

export { default } from './MVP';
